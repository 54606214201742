@import "variables";
@import "base/module";
@import "components/module";

body, input, textarea {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  padding: 0;
}

#root {
  margin-top: 60px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.system-instruction {
  padding: 15px 10px;
  background-color: #cbcb00;
  color: #000;
  font-size: 16px;
}

.version {
  position: fixed;
  left: 1px;
  bottom: 1px;
  color: #00A29F;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 8px;
  padding: 5px;
}

