.inventory-part-actions {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background-color: white;

  .inventory-part-add {
    @include style-button;
    width: 35%;
    float: left;
  }

  .inventory-part-remove {
    @include style-button;
    width: 35%;
    float: left;
  }

  .inventory-part-qty {
    padding: 0;
    margin: 0 5%;
    background-color: white;
    color: black;
    font-weight: bold;
    border-radius: 3px;
    width: calc(20%);
    float: left;
    font-size: 24px;
    text-align: center;

    &.blue {
      border-color: $primary-color;
    }
  }
}

.inventory-wanted-actions {
  margin: 0 0 10px 0;
}