form, .form {

  &.login-form {
    border: 3px solid $primary-color;
    //box-shadow: 0 0 12px 0 #aaa;
    width: 300px;
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, 0%);
    padding: 15px;
    background: #fff;

    ul {
      width: 100%;
    }

    li {
      grid-column: 1/4 !important;
    }
  }

  margin: 0 !important;
  padding: 16px 8px;

  select {
    @include style-select;
  }
  button, .button {
    @include style-button;
    width: 100%;

    &.medium {
      width: 200px;
      margin: 5px;
    }

    &.green {

    }

    &.hovered {
      animation: blinker 2s ease-in-out infinite;
      background-color: $primary-color;
      color: #fff;
    }

    &.blue {
      //background-color: $primary-color;
      margin-top: 10px;
      //color: white;
      cursor: pointer;
      float: left;
      width: 100%;

      &:hover {
        background-color: $second-color;
      }
    }
  }

  .toolbar {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin: 10px 0;
  }

  ul {
    list-style: none;
    padding: 0;

    grid-template-columns: repeat(3, 1fr) !important;
    //width: calc(100% - 20px);

    display: grid;
    margin: 0 !important;
    grid-gap: 20px;

    li {
      position: relative;
      grid-column: 1/4;

      .checkbox {
        float: left;
        width: 20%;
      }
      .wanted {
        float: left;
        width: 20%;
        //position: absolute;
        //left: 200px;
        //top: 0;
      }
      .match {
        float: left;
        width: 20%;
        padding: 5px 10px;
        border-radius: 5px;
        color: white;
        background-color: black;
        font-size: 16px;
      }
    }

    @include medium() {
      li:nth-child(even) {
        grid-column: 2/4;
      }

      li:nth-child(odd) {
        grid-column: 1/2;
      }

      li:last-child {
        grid-column: 1/4;
      }
    }
  }

  ol {
    list-style: none;
    padding: 0;
    //width: calc(100% - 20px);

    &.sub-parts {
      border: 5px solid rgba($primary-color, .2);
      ul {
        border-bottom: 1px solid rgba($primary-color, .2);
      }
    }

    li {
      margin: 10px;
      border-bottom: 1px solid rgba($primary-color, .2);

      .expected {
        padding: 10px 0;
      }
    }

    ul {
      grid-template-columns: repeat(12, 1fr) !important;
      grid-gap: 10px;
      li {
        border-bottom: 0;
        display: block !important;
        &:nth-child(1) {
          grid-column: 1/2;
        }
        &:nth-child(2) {
          grid-column: 2/3;
          select {
            width: 64px;
            font-size: 20px;
            font-weight: bold;
            float: left;
            margin: 0 5px 0 0;
          }
          button {
            float: left;
            width: 100%;
            margin: 10px 0;
          }
        }
        &:nth-child(3) {
          grid-column: 3/11;
          font-size: 16px;
        }
        &:nth-child(4) {
          label {
            text-align: left;
          }
          input[type="checkbox"] {
            float: left;
            margin: 0 5px;
          }
          grid-column: 11/13;
        }
      }
    }
  }

  li, div {

    .form-error {
      @include style-label;
      color: red;
    }

    label {
      @include style-label;
    }

    input {
      @include style-input;
    }
    select {
      @include style-select;
    }
    textarea {
      @include style-input;
    }

    button {
      @include style-button;
      cursor: pointer;
      padding: 10px 0;
    }
  }

  &_search {
    padding: 0;
  }

  .set_preview {
    width: 25%;
  }
}
