.game {
  padding: 20px;
  position: relative;
  .count {
    font-size: 36px;
    font-weight: bold;
    text-align: right;
    position: absolute;
    top: 15px;
    right: 15px;
  }
  .board {
    //border: 2px solid $primary-color;
    //border-radius: 10px;
    .part {
      padding: 20px 0;
      .green {
        img {
          border-color: green;
        }
      }
      .red {
        img {
          border-color: red;;
        }
      }
      img {
        max-width: 600px;
        width: auto;
        height: 50%;
        min-height: 400px;
        object-fit: contain;
        border: 5px solid white;
        border-radius: 5px;
        margin: 0 auto;
        display: flex;
        padding: 1px;
      }
    }
    ul {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 10px;
      grid-auto-flow: column;
      padding: 10px;
      list-style: none;
      li {
        border: 1px solid $primary-color;
        text-align: center;
        border-radius: 5px;
        padding: 15px 10px 15px;
        margin: 5px;
        cursor: pointer;

        &.green {
          background-color: green !important;
          border: 1px solid #004d00;
          color: white !important;
        }
        &.red {
          background-color: red !important;
          border: 1px solid #a20101;
          color: white !important;
        }

        &:hover {
          background-color: $second-color;
          color: #fff;
        }
      }
    }
  }
}
