.imageWidget {

  color: #000;
  text-align: left;
  margin: 4px;

  .color-name {
    font-size: 12px;
  }

  .preview {
    img {
      width: 100%;
      height: 100%;
      //height: 120px;
      //min-height: 120px;
      object-fit: contain;

      &.original {
        height: 360px;
        width: 240px;
      }
    }
    position: relative;

    .magnifier {

      &:before {
        content: "";
        position: absolute;
        top: 9px;
        left: 8px;
        width: 4px;
        height: 3px;
        background-color: rgba($primary-color, .8);
        border-radius: 3px;
        transform: rotate(45deg);
      }
      &:after {
        content: "";
        position: absolute;
        top: 1px;
        left: 1px;
        width: 6px;
        height: 6px;
        //background-color: black;
        border: 2px solid rgba($primary-color, .8);
        border-radius: 6px;
        overflow: hidden;
        background-color: rgba($primary-color, .2);
      }

      width: 14px;
      height: 14px;
      border: 1px solid rgba($primary-color, .2);
      //background-color: rgba($primary-color, .1);
      color: #fff;
      cursor: pointer;
      //overflow: hidden;

      position: absolute;
      left: 0;
      top: 0;

      .submenu {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        border: 10px solid white;
        background-color: white;
        box-shadow: #1a202c 0 0 1px;
        text-align: left;
        color: black;

        .title {
          font-weight: bold;
          text-transform: uppercase;
          color: #000;
          margin-bottom: 20px;

          .completeness {
            font-weight: normal;
          }
        }

        .buttons {
          a {
            margin-right: 3px;
            margin-bottom: 4px;
            font-size: 12px;
            display: inline-block;
          }
        }

        .note {
          padding-top: 10px;
          font-size: 10px;
          line-height: 120%;
        }
      }

      &:hover {
        top: 0;
        left: 0;

        .submenu {
          display: block;
          //width: 480px;
          z-index: 100;
          img {
            max-width: 360px;
            width: auto;
          }
        }
      }
    }
  }
}

.image-widget-small-image {
  .imageWidget {
    img {
      height: 40px;
    }
  }
}

