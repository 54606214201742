@mixin color-border {
  border: 3px solid white;
  border-radius: 5px;
  padding: 2px;
}

.color-border-0 {
  @include color-border;
  border-color: transparent;
}

.color-border-1 {
  @include color-border;
  border-color: #000;
  border-width: 1px;
  padding: 6px;
}


.color-border-2 {
  @include color-border;
  border-color: #DEC69C;
}


.color-border-3 {
  @include color-border;
  border-color: #FFE001;
}


.color-border-4 {
  @include color-border;
  border-color: #FF7E14;
}


.color-border-5 {
  @include color-border;
  border-color: #B30006;
}


.color-border-6 {
  @include color-border;
  border-color: #00923D;
}


.color-border-7 {
  @include color-border;
  border-color: #0057A6;
}


.color-border-8 {
  @include color-border;
  border-color: #532115;
}


.color-border-9 {
  @include color-border;
  border-color: #9C9C9C;
}


.color-border-10 {
  @include color-border;
  border-color: #6B5A5A;
}


.color-border-11 {
  @include color-border;
  border-color: #212121;
}


.color-border-12 {
  @include color-border;
  border-color: #EEEEEE;
}


.color-border-13 {
  @include color-border;
  border-color: #939484;
}


.color-border-14 {
  @include color-border;
  border-color: #00296B;
}


.color-border-15 {
  @include color-border;
  border-color: #68BCC5;
}


.color-border-16 {
  @include color-border;
  border-color: #C0F500;
}


.color-border-17 {
  @include color-border;
  border-color: #9C0010;
}


.color-border-18 {
  @include color-border;
  border-color: #FF4231;
}


.color-border-19 {
  @include color-border;
  border-color: #EBF72D;
}


.color-border-20 {
  @include color-border;
  border-color: #217625;
}


.color-border-21 {
  @include color-border;
  border-color: #f1f2e1;
}


.color-border-22 {
  @include color-border;
  border-color: #DCDCDC;
}


.color-border-23 {
  @include color-border;
  border-color: #FFC0CB;
}


.color-border-24 {
  @include color-border;
  border-color: #A5499C;
}


.color-border-25 {
  @include color-border;
  border-color: #FF7D5D;
}


.color-border-26 {
  @include color-border;
  border-color: #FCC7B7;
}


.color-border-27 {
  @include color-border;
  border-color: #B22222;
}


.color-border-28 {
  @include color-border;
  border-color: #FFAF7D;
}


.color-border-29 {
  @include color-border;
  border-color: #E6881D;
}


.color-border-31 {
  @include color-border;
  border-color: #FFA531;
}


.color-border-32 {
  @include color-border;
  border-color: #F7AD63;
}


.color-border-33 {
  @include color-border;
  border-color: #FFE383;
}


.color-border-34 {
  @include color-border;
  border-color: #C4E000;
}


.color-border-35 {
  @include color-border;
  border-color: #ECEEBD;
}


.color-border-36 {
  @include color-border;
  border-color: #10CB31;
}


.color-border-37 {
  @include color-border;
  border-color: #62F58E;
}


.color-border-38 {
  @include color-border;
  border-color: #A5DBB5;
}


.color-border-39 {
  @include color-border;
  border-color: #00A29F;
}


.color-border-40 {
  @include color-border;
  border-color: #00C5BC;
}


.color-border-41 {
  @include color-border;
  border-color: #BCE5DC;
}


.color-border-42 {
  @include color-border;
  border-color: #82ADD8;
}


.color-border-43 {
  @include color-border;
  border-color: #3448A4;
}


.color-border-44 {
  @include color-border;
  border-color: #C9CAE2;
}


.color-border-46 {
  @include color-border;
  border-color: #d4d5c9;
}


.color-border-47 {
  @include color-border;
  border-color: #EF5BB3;
}


.color-border-48 {
  @include color-border;
  border-color: #A2BFA3;
}


.color-border-49 {
  @include color-border;
  border-color: #E8E8E8;
}


.color-border-50 {
  @include color-border;
  border-color: #CE1d9b;
}


.color-border-51 {
  @include color-border;
  border-color: #5525B7;
}


.color-border-52 {
  @include color-border;
  border-color: #5C66A4;
}


.color-border-54 {
  @include color-border;
  border-color: #B57DA5;
}


.color-border-55 {
  @include color-border;
  border-color: #8899AB;
}


.color-border-56 {
  @include color-border;
  border-color: #FFE1FF;
}


.color-border-57 {
  @include color-border;
  border-color: #645a4c;
}


.color-border-58 {
  @include color-border;
  border-color: #8C6B6B;
}


.color-border-59 {
  @include color-border;
  border-color: #6A0E15;
}


.color-border-60 {
  @include color-border;
  border-color: #d4d3dd;
}


.color-border-61 {
  @include color-border;
  border-color: #E7AE5A;
}


.color-border-62 {
  @include color-border;
  border-color: #C8D9E1;
}


.color-border-63 {
  @include color-border;
  border-color: #243757;
}


.color-border-64 {
  @include color-border;
  border-color: #3CB371;
}


.color-border-65 {
  @include color-border;
  border-color: #B8860B;
}


.color-border-66 {
  @include color-border;
  border-color: #ACB7C0;
}


.color-border-67 {
  @include color-border;
  border-color: #C0C0C0;
}


.color-border-68 {
  @include color-border;
  border-color: #B35408;
}


.color-border-69 {
  @include color-border;
  border-color: #907450;
}


.color-border-70 {
  @include color-border;
  border-color: #bdb573;
}


.color-border-71 {
  @include color-border;
  border-color: #B72276;
}


.color-border-72 {
  @include color-border;
  border-color: #7DC1D8;
}


.color-border-73 {
  @include color-border;
  border-color: #9391E4;
}


.color-border-74 {
  @include color-border;
  border-color: #7384A5;
}


.color-border-76 {
  @include color-border;
  border-color: #DFE000;
}


.color-border-77 {
  @include color-border;
  border-color: #666660;
}


.color-border-78 {
  @include color-border;
  border-color: #5686AE;
}


.color-border-80 {
  @include color-border;
  border-color: #2E5543;
}


.color-border-81 {
  @include color-border;
  border-color: #AD7118;
}


.color-border-82 {
  @include color-border;
  border-color: #aa4d8e;
}


.color-border-83 {
  @include color-border;
  border-color: #FFFFFF;
}


.color-border-84 {
  @include color-border;
  border-color: #C66921;
}


.color-border-85 {
  @include color-border;
  border-color: #595D60;
}


.color-border-86 {
  @include color-border;
  border-color: #AFB5C7;
}


.color-border-87 {
  @include color-border;
  border-color: #8AD4E1;
}


.color-border-88 {
  @include color-border;
  border-color: #89351D;
}


.color-border-89 {
  @include color-border;
  border-color: #5F2683;
}


.color-border-90 {
  @include color-border;
  border-color: #FECCB0;
}


.color-border-91 {
  @include color-border;
  border-color: #774125;
}


.color-border-93 {
  @include color-border;
  border-color: #DA70D6;
}


.color-border-94 {
  @include color-border;
  border-color: #F785B1;
}


.color-border-95 {
  @include color-border;
  border-color: #8D949C;
}


.color-border-96 {
  @include color-border;
  border-color: #E6C05D;
}


.color-border-97 {
  @include color-border;
  border-color: #506CEF;
}


.color-border-98 {
  @include color-border;
  border-color: #D04010;
}


.color-border-99 {
  @include color-border;
  border-color: #E4E8E8;
}


.color-border-100 {
  @include color-border;
  border-color: #CE1d9b;
}


.color-border-101 {
  @include color-border;
  border-color: #b2adaa;
}


.color-border-102 {
  @include color-border;
  border-color: #3A2B82;
}


.color-border-103 {
  @include color-border;
  border-color: #FEED83;
}


.color-border-104 {
  @include color-border;
  border-color: #F7BCDA;
}


.color-border-105 {
  @include color-border;
  border-color: #BCD1ED;
}


.color-border-106 {
  @include color-border;
  border-color: #B3694E;
}


.color-border-107 {
  @include color-border;
  border-color: #FF8298;
}


.color-border-108 {
  @include color-border;
  border-color: #10Cb31;
}


.color-border-109 {
  @include color-border;
  border-color: #2032B0;
}


.color-border-110 {
  @include color-border;
  border-color: #FFC700;
}


.color-border-111 {
  @include color-border;
  border-color: #7C7E7C;
}


.color-border-113 {
  @include color-border;
  border-color: #B7C8BF;
}


.color-border-114 {
  @include color-border;
  border-color: #B97AB1;
}


.color-border-115 {
  @include color-border;
  border-color: #E79E1D;
}


.color-border-116 {
  @include color-border;
  border-color: #5F4E47;
}


.color-border-117 {
  @include color-border;
  border-color: #4A6363;
}


.color-border-118 {
  @include color-border;
  border-color: #bdc6ad;
}


.color-border-119 {
  @include color-border;
  border-color: #D4D2CD;
}


.color-border-120 {
  @include color-border;
  border-color: #330000;
}


.color-border-121 {
  @include color-border;
  border-color: #FFD700;
}


.color-border-122 {
  @include color-border;
  border-color: #544E4F;
}


.color-border-150 {
  @include color-border;
  border-color: #E3A05B;
}


.color-border-151 {
  @include color-border;
  border-color: #AB9421;
}


.color-border-152 {
  @include color-border;
  border-color: #D8EFDD;
}


.color-border-153 {
  @include color-border;
  border-color: #009FE0;
}


.color-border-154 {
  @include color-border;
  border-color: #D3BDE3;
}


.color-border-155 {
  @include color-border;
  border-color: #ABA953;
}


.color-border-156 {
  @include color-border;
  border-color: #6ACEE0;
}


.color-border-157 {
  @include color-border;
  border-color: #C689D9;
}


.color-border-158 {
  @include color-border;
  border-color: #E7F2A7;
}


.color-border-159 {
  @include color-border;
  border-color: #d9d9d9;
}


.color-border-160 {
  @include color-border;
  border-color: #EF9121;
}


.color-border-161 {
  @include color-border;
  border-color: #DD982E;
}


.color-border-162 {
  @include color-border;
  border-color: #68BCC5;
}


.color-border-163 {
  @include color-border;
  border-color: #C0F500;
}


.color-border-164 {
  @include color-border;
  border-color: #E99A3A;
}


.color-border-220 {
  @include color-border;
  border-color: #FF8172;
}


.color-border-221 {
  @include color-border;
  border-color: #94E5AB;
}


.color-border-222 {
  @include color-border;
  border-color: #D04010;
}


.color-border-223 {
  @include color-border;
  border-color: #68BCC5;
}


.color-border-224 {
  @include color-border;
  border-color: #CE1d9b;
}


.color-border-225 {
  @include color-border;
  border-color: #E78B3E;
}


.color-border-226 {
  @include color-border;
  border-color: #34EF55;
}


.color-border-227 {
  @include color-border;
  border-color: #E0AAD9;
}


.color-border-228 {
  @include color-border;
  border-color: #FFFFFF;
}


.color-border-229 {
  @include color-border;
  border-color: #939484;
}


.color-border-230 {
  @include color-border;
  border-color: #8320B7;
}


.color-border-231 {
  @include color-border;
  border-color: #FF6326;
}


.color-border-232 {
  @include color-border;
  border-color: #1552e2;
}


.color-border-233 {
  @include color-border;
  border-color: #7fe15b;
}


.color-border-234 {
  @include color-border;
  border-color: #9C41B6;
}


.color-border-235 {
  @include color-border;
  border-color: #E7891B;
}


.color-border-236 {
  @include color-border;
  border-color: #FFFC00;
}

