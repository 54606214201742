$localhostColor: red;

.localhost {
  background-color: $localhostColor;

  ul, li {
    //background-color: $localhostColor;
  }

  button, a {
    //background-color: darkred !important;
    //border: 0 !important;
  }

 .nav-menu {
   border-color: white !important;
   background-color: $localhostColor !important;
   button, a {
     background-color: $localhostColor !important;
     border: 0 !important;
   }
  }
}

nav {
  border: 0;

  padding: 8px 10px;
  margin: 0;
  text-align: right;

  background-color: $primary-color;

  transition: top 300ms ease 0s;

  &.global {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
  }

  input {
    width: auto;
  }

  hr {
    border: 0;
    height: 2px;
    background-color: white;
  }

  .nav-search {
    form {
      padding: 0;
    }
    float: left;
    padding: 4px 0 0 10px;
    input {
      @include style-input;
    }
    position: relative;

    .child {
      position: absolute;
      background-color: white;
      padding: 10px;
      border: 2px solid black;
      margin: 10px 0 0 0;

      li, .button {
        background-color: white !important;
      }

      .button {
        @include style-button
      }
    }
  }

  button {
    &.toggle {     ;
      @include style-button;
      background-color: $primary-color;
      border: 0;
      line-height: 40px;
      color: white;
      padding: 2px 12px;
      max-width: 80px;
      outline: none;
      cursor: pointer;
      display: inline-block;
    }
  }

  &.second-line {
    background-color: $second-color;
    padding: 0;
    color: #fff;

    .nav-title {
      font-weight: bold;
      padding: 3px 0 1px;
      color: #fff;
      text-align: left;
      font-size: 24px;
      text-transform: uppercase;
    }

    ul {

      &.inventory-minifig, &.inventory-parts {
        li:nth-child(1){
          grid-column: 7/12;
        }
      }

      &.sets-used, &.sets-new {
        text-align: right;
        li:nth-child(1) {
          display: block !important;
          grid-column: 1/5;
        }
        li:nth-child(2) {
          grid-column: 5/7;
        }
        li:nth-child(3) {
          grid-column: 7/12;
        }
      }

      &.set-inventory {
        text-align: right;
        input[type="checkbox"] {
          float: left;
          margin: 0 5px;
        }
        li:nth-child(1) {
          display: block !important;
          grid-column: 1/4;
        }
        li:nth-child(2) {
          display: block !important;
          grid-column: 4/6;
        }
        li:nth-child(3) {
          display: block !important;
          grid-column: 6/8;
          vertical-align: center;
        }
        li:nth-child(4) {
          display: block !important;
          grid-column: 8/10;
        }
        li:nth-child(5) {
          display: block !important;
          grid-column: 10/12;
        }
      }

      &.inventory-print {
        text-align: right;
        input[type="checkbox"] {
          float: left;
          margin: 0 5px;
        }

        li:nth-child(1) {
          display: block !important;
          grid-column: 9/11;
        }
        li:nth-child(2) {
          display: block !important;
          grid-column: 11/13;
        }
      }

      &.sets-missing-parts {
        grid-template-columns: repeat(12, 1fr) !important;
        li {

          &:nth-child(1){
            grid-column: 1/5;
            label {
              text-align: right;
            }
          }
          &:nth-child(2){
            grid-column: 5/7;
          }

          &:nth-child(3){
            grid-column: 7/8;
            label {
              text-align: right;
            }
          }

          &:nth-child(4) {
            grid-column: 8/10;
          }

          label {
            text-align: left;
          }
        }
        input[type="checkbox"] {
          float: left;
          margin: 0 5px;
        }
      }

      list-style: none;
      padding: 0;

      grid-template-columns: repeat(5, 1fr) !important;
      width: calc(100%);

      display: grid;
      margin: 0 !important;
      grid-gap: 20px;

      li {
        grid-column: auto;
      }

      //li:nth-child(even) {
      //  grid-column: 2/4;
      //}
      //
      //li:nth-child(odd) {
      //  grid-column: 1/2;
      //}
      //
      //li:last-child {
      //  grid-column: 1/4;
      //}
      //
      //@include medium {
      //  grid-template-columns: repeat(6, 1fr) !important;
      //
      //  li:nth-child(even) {
      //    grid-column: 2/7;
      //  }
      //
      //  li:nth-child(odd) {
      //    grid-column: 1/2;
      //  }
      //
      //  li:last-child {
      //    grid-column: 1/7;
      //  }
      //}
      //
      //@include large {
      //  grid-template-columns: repeat(5, 1fr) !important;
      //
      //  li:nth-child(even), li:last-child {
      //    grid-column: auto;
      //  }
      //}
    }


    label {
      @include style-label;
      text-align: left;
      position: relative;
      padding-left: 25px;
      input[type="checkbox"] {
        position: absolute;
        left: 0;
      }
    }

    select {
      @include style-input;
    }

    button, .button {
        @include style-button;
        &:hover {
          background-color: black;
        }
    }
  }

  .nav-menu {

    display: none;
    background-color: $primary-color;
    color: white;

    a {
      color: white;
      background-color: transparent;
    }

    &.mobile-opened {
      display: grid;
      border-top: 3px solid $second-color;
    }

    grid-template-columns: repeat(1, 1fr);
    width: calc(100% - 20px);


    margin: 20px auto;
    grid-gap: 20px;

    @include medium {
      grid-template-columns: repeat(4, 1fr);
    }

    @include large {
      grid-template-columns: repeat(8, 1fr);
    }

    ul {

      @include large {
        &:first-child {
          grid-column: 1/5;
        }
      }

      li {
        padding: 0;
        margin: 0;
        font-size: 16px;

        &.section {
          font-weight: bold;
          margin-top: 20px;
          font-size: 24px;
          line-height: 36px;

          @include medium {
            margin-bottom: 20px;
          }
        }

        a {
          @include style-link;
          font-size: 16px;
          color: #fff;
          margin: 8px 0;
          display: block;
          background-color: transparent;
          &:hover {
            color: #000;
          }
        }
      }
    }
  }
}


@mixin data-box {
  position: fixed;
  bottom: -100px;
  right: 10px;
  text-align: center;
  font-size: 24px;
  z-index: 100;
  display: block;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 6px 20px 10px 20px;
  width: auto;
  border-radius: 10px;
  -webkit-transition: bottom 0.2s ease-in-out;
  -moz-transition: bottom 0.2s ease-in-out;
  transition: bottom 0.2s ease-in-out;

  &.visible {
    bottom: 10px;
  }
}

.data-sending {
  @include data-box;
  background-color: rgba(40, 38, 38, 0.7);
}

.data-success {
  @include data-box;
  background-color: rgba(5, 162, 0, 0.95);
}

.data-error {
  @include data-box;
  background-color: red;
  left: 10px;
}
