$gap: 20px;

$screen-md-min: 720px;
$screen-lg-min: 1280px;
@mixin medium {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin large {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

$primary-color: #015fcc;
$second-color: #0050b9;
$third-color: #212222;

$four-color: grey;

$text-color: #000;


$link-color: #000;
$link-color-hover: #fff;
$link-color-active: #fff;

$input-border: #000;
$input-text: #000;

$input-border-hover: #000;
$input-text-hover: #000;

$input-border-active: #000;
$input-text-active: #000;

$button-border: #000;
$button-text: #000;
$button-background: #000;

$button-border-hover: #000;
$button-text-hover: #000;
$button-background-hover: #000;

$button-border-active: #000;
$button-text-active: #000;
$button-background-active: #000;

@mixin style-h1 {
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  color: $text-color;

  &.big {
    font-size: 36px;
    line-height: 42px;
  }
}

@mixin style-text {
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
}

@mixin style-text-small {
  font-size: 12px;
  line-height: 16px;
  color: $text-color;
}

@mixin style-link {
  font-size: 14px;
  color: $link-color;
  //background-color: $second-color;
  border-radius: 3px;
  padding: 1px 4px 2px;
  text-decoration: none;
  //border-bottom: 2px solid $second-color;
  border: 1px solid $primary-color;
  background-color: #fff;
  //margin: 2px 4px;
  cursor: pointer;

  &:hover {
    color: $link-color-hover;
    background-color: $primary-color;
  }

  &:active {
    color: $link-color-active;
    background-color: $primary-color;
    transform: translate(0, 1px);
  }
}

@mixin style-button {

    font-size: 16px;
    display: block;
    //width: 100%;
    text-align: center;
    cursor: pointer;

    margin: 0;

    border: 1px solid $primary-color;
    background-color: #fff;

    border-radius: 3px;

    padding: 6px 10px;

    outline: none;
    box-sizing: border-box;

    &:hover {
      background-color: $primary-color;
      color: white;
    }

    &:active {
      transform: translate(0, 1px);
    }

    &.danger {
      background-color: #ff0000;
      color: white;
    }
  }

@mixin style-label {
  text-align: right;

  margin: 0;

  font-size: 14px;

  padding: 6px 0;
  display: block;
}

@mixin style-input {
  text-align: left;
  width: calc(100%);
  margin: 0;

  font-size: 16px;

  padding: 6px 10px;

  border-radius: 3px;
  border: 1px solid #2d3748;

  outline: none;

  box-sizing: border-box;

  &:hover, &:active {
    border: 1px solid #015fcc;
  }
}
@mixin style-tag {
  font-size: 16px;
  color: $primary-color;
  border: 1px solid $primary-color;
  display: inline-block;
  margin: 10px 0 0;
  padding: 2px 4px;
}

@mixin style-select {

  text-align: left;

  width: calc(100%);
  margin: 0;

  font-size: 16px;

  padding: 6px 10px;

  outline: none;

  border-radius: 3px;
  border: 1px solid #2d3748;

  box-sizing: border-box;
}

@mixin checkbox {
  input[type="checkbox"] {

    width: 20px;
    height: 20px;
    font-size: 16px;
    border: 1px solid black;
    padding: 3px 3px;
    resize: none;
    cursor: pointer;

    &:hover {
      border: 1px solid $primary-color;
    }
  }
}

@include checkbox;
