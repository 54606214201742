.found-in-wanted {

  position: fixed;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  background-color: white;
  z-index: 101;
  border: 8px solid $second-color;
  box-shadow: 0 0 3px 1px $third-color;

  .source {
    float: left;
    width: 100%;
    height: 71px;
    border-bottom: 8px solid $primary-color;

    .part {
      float: left;
      padding-left: 20px;
      .magnifier {
        display: none;
      }
      img {
        height: 60px;
        min-height: 60px;
      }
    }
    h1 {
      //background-color: $third-color;
      //padding: 10px 10px 20px 10px;
      //margin: 0 0 10px 0;
      //color: white;
      text-transform: uppercase;
      line-height: 36px;
      float: left;
      padding-left: 20px;
    }
    .close-btn {
      @include style-button;
      position: absolute;
      top: 18px;
      right: 18px;
      background-color: white;
      color: black;
      cursor: pointer;
      padding: 6px 5px 5px 5px;
      text-transform: uppercase;
      border: 1px solid $primary-color;
      border-radius: 3px;

      &:hover {
        background-color: $primary-color;
        color: white;
      }
    }
  }

  .sets {
    float: left;
    width: 100%;

    overflow: hidden;
    overflow-y: scroll;
    height: calc(100% - 79px);

    strike {
      text-transform: none;
      font-weight: bold;
      font-style: normal;
      text-decoration: none;
    }

    .set {
      height: 144px;
      width: 100%;
      border-bottom: 1px solid $primary-color;

      .imageWidget {
        width: 200px;
        float: left;
      }

      .desc {
        float: left;
        width: 100%;
        font-weight: normal;

        b {
          display: block;
          margin: 10px 0 20px;
          text-align: left;
        }

        small {
          display: block;
          text-align: left;
          position: relative;

          .icons {
            position: absolute;
            text-align: center;
            right: 30px;
            top: -20px;

            div {
              float: left;
            }
          }
        }

        .btn {
          @include style-button;
          margin: 20px 10px 10px 0;

          padding: 5px 10px 6px;
          float: left;
          cursor: pointer;
        }
      }
    }
  }

}
