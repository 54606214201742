table {
  tr, th, td {
    padding: 5px 20px;
    text-align: center;
  }

  td {
    &.image {
      width: 80px;
    }
  }
}

.table {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 10px;
  grid-auto-flow: column;
  border-bottom: 1px solid #ccc;
  padding: 10px;

  a {
    margin: 3px;
  }

  &:hover {
    background-color: #f3f3f3;
  }

  &.header {
    font-weight: bold;
    text-align: center;
    &:hover {
      background-color: white;
    }

    div {
      border-right: 1px solid #ccc;

      &.options-cell {
        border-right: 0;
      }
    }
  }

  .id {
    grid-column: 1/2;
    text-align: center;

    b {
      padding: 32px 8px 0;
      font-size: 48px;
      font-weight: bold;
      display: block;
    }
  }

  .image {
    grid-column: 2/3;
    text-align: center;
    padding-left: 8px;
  }
  .name {
    grid-column: 3/8;
  }

  .price {
    grid-column: 8/9;
    text-align: center;
  }

  .missing {
    grid-column: 9/10;
    text-align: center;

    div {
      float: left;
      font-size: 14px;
      color: #333;

      svg {
        path {
          fill: #333;
        }
      }
    }
  }

  .wanted {
    grid-column: 10/11;
    text-align: center;

    div {
      float: left;
      font-size: 14px;
      color: #333;

      svg {
        path {
          fill: #333;
        }
      }
    }
  };

  .options {
    grid-column: 11/13;
    text-align: center;
  }

  .name-cell {
    grid-column: 2/7
  }

  .options-cell {
    grid-column: 11/13;
  }
}
