.spotlight {
  border: 5px solid black;
  position: fixed;
  top: 50px;
  bottom: 20px;
  left: 20px;
  right: 20px;
  padding: 20px 20px 500px 20px;
  background-color: white;
  overflow: auto;

  .spotlight-close-button {
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    @include style-button;
    //float: right;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 6px 10px;
    border-radius: 5px;
    border-width: 2px;
  }

  .spotlight-header {
    text-align: left;
    h3 {
      padding: 0;
      margin: 20px 0 5px;
      border-bottom: 2px solid black;
    }
  }

  .spotlight-group {
    padding: 0;
  }
}