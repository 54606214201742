.album {

  //grid-template-columns: repeat(12, 1fr);

  //.album-element {
  //  padding: 10px;
  //  margin: 0;
  //  img {
  //    float: left;
  //    width: 100%;
  //    height: 100%;
  //    max-height: 100px;
  //    object-fit: contain;
  //    object-position: center;
  //  }
  //  a {
  //    border: 0;
  //  }
  //}

  .album-category {
    padding: 5px;

    .tag {
      margin: 5px 0 0 0;
    }

    img {
      float: left;
      width: 50%;
      height: 100px;
      max-height: 100px;
      object-position: center;
      object-fit: contain;
    }

  }

  .button {
    @include style-button;
    margin: 10px 0 0 0;
  }

}
