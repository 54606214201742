ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {

  @include style-link;

  &.link-allegro, &.link-bricklink, &.link-search {
    @include style-link;
    font-size: 12px;
    display: inline-block;
    margin-top: 0;
    position: relative;
    border: 1px solid $second-color;

    &:hover {
      background-color: $primary-color;
      cursor: pointer;
    }
  }

  &.link-category {
    @include style-button;
    border: 1px solid $primary-color;
    color: $primary-color;
    padding: 1px 4px;
    font-size: 20px;
    float: left;
    margin-top: 10px;
  }

  //&.link-allegro {
  //  padding-right: 22px;
  //  &:before {
  //    content: "A";
  //  }
  //}
  //
  //&.link-bricklink {
  //  padding-right: 22px;
  //  &:before {
  //    content: "B";
  //  }
  //}
}

.tag {
  @include style-text;
  @include style-tag;
}

h1 {
  @include style-h1;
}

small {
  @include style-text-small;
}

.grid-auto-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  grid-auto-flow: column;
  padding: 10px;
  list-style: none;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 10px;
  grid-auto-flow: column;
  padding: 10px;
  list-style: none;

  &.grid-border-bottom {
    border-bottom: 1px solid silver;
  }

  div, li {
    text-align: center;

    &.grid-align-left {
      text-align: left;
    }

    &.grid-align-right {
      text-align: right;
    }

    &.grid-start {
      grid-column-start: 1;
    }
    &.grid-start-1 {
      grid-column-start: 2;
    }
    &.grid-start-2 {
      grid-column-start: 3;
    }
    &.grid-start-3 {
      grid-column-start: 4;
    }
    &.grid-start-4 {
      grid-column-start: 5;
    }
    &.grid-start-5 {
      grid-column-start: 6;
    }
    &.grid-start-6 {
      grid-column-start: 7;
    }
    &.grid-start-7 {
      grid-column-start: 8;
    }
    &.grid-start-8 {
      grid-column-start: 9;
    }
    &.grid-start-9 {
      grid-column-start: 10;
    }
    &.grid-start-10 {
      grid-column-start: 11;
    }
    &.grid-start-11 {
      grid-column-start: 12;
    }


    &.grid-end-1 {
      grid-column-end: 2;
    }
    &.grid-end-2 {
      grid-column-end: 3;
    }
    &.grid-end-3 {
      grid-column-end: 4;
    }
    &.grid-end-4 {
      grid-column-end: 5;
    }
    &.grid-end-5 {
      grid-column-end: 6;
    }
    &.grid-end-6 {
      grid-column-end: 7;
    }
    &.grid-end-7 {
      grid-column-end: 8;
    }
    &.grid-end-8 {
      grid-column-end: 9;
    }
    &.grid-end-9 {
      grid-column-end: 10;
    }
    &.grid-end-10 {
      grid-column-end: 11;
    }
    &.grid-end-11 {
      grid-column-end: 12;
    }
    &.grid-end {
      grid-column-end: 13;
    }
  }
}

.grid-id-column {
  text-align: center;
  b {
    padding: 32px 8px 0;
    font-size: 48px;
    font-weight: bold;
  }
}

.standalone-button {
  @include style-button;
  max-width: 200px;
  margin: 20px auto;
}
