.print {
  form {
    float: left;
    padding: 0;
    margin: 20px 0 0 0 !important;
  }
  h2 {
    height: 43px;
    padding: 90px 0 0;
    margin: 0;
  }
  ol {
    width: 100%;
    list-style: none;
    padding: 0;
    margin:  0;
    float: left;

    li {
      float: left;
      width: 10%;
      padding: 0;
      margin: 0;
      border: 0;
      //background-color: red;

      .imageWidget {
        margin: 0;
        padding: 0;
        width: 100%;
        //background-color: yellow;
      }

      img {
        width: 100%;
        object-fit: contain;
        height: 90px !important;
        min-height: 90px !important;
        padding: 0;
      }

      .name {
        padding: 0;
        font-size: 12px;
        text-align: center;
        height: 12px;
        overflow: hidden;
      }

      .num {
        padding: 3px 0 5px;
        font-size: 12px;
        text-align: center;
      }

      .image {
        width: 100%;
        height: 60px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
}

.print {
  width: 100%;
  .line {
    padding: 150px 0;
    width: calc(100%);
    float: left;

    .point {
      padding: 20px 50px;
      position: relative;
      width: 33%;
      float: left;
      //border: 1px solid red;
      box-sizing: border-box;

      &.full-set {
        &:before {
          background-color: black;

        }
      }

      &:before {
        content: "";
        border: 2px solid black;
        width: 30px;
        height: 30px;
        position: absolute;
        display: block;
        left: 0;
        top: 10px;
        background-color: white;
      }
    }
  }
}

@media print {
  .version {
    display: none;
  }
  #root {
    margin: 0 !important;
  }
  .no-print, nav, .second-line, .magnifier { display: none !important; }
  .data-sending, .data-success, .data-error {
    display: none !important;
  }

  .no-print {
    display: none;
  }

  .print-text-normal {
    color: black !important;
    font-size: 16px !important;
  }

  .print-image-width {
    grid-column: 1/3 !important;
  }
  .print-name-width {
    grid-column: 3/13 !important;
  }

  .imageWidget {
    margin: 20px 10px 0 0 !important;

    .magnifier {
      display: none;
    }
  }

  h1 {
    font-size: 36px !important;
    line-height: 36px !important;
    margin-bottom: 20px !important;
    padding-bottom: 0 !important;

    &.big {
      font-size: 56px !important;
      line-height: 48px !important;
    }
  }

  .notes {
    font-size: 12px;
  }

  @page {
    size: A4; /* DIN A4 standard, Europe */
    margin: 10mm;
  }
  html, body {
    width: 210mm;
    /* height: 297mm; */
    height: 282mm;
    font-size: 11px;
    background: #FFF;
    overflow:visible;
  }
  body {
    padding-top:15mm;
  }
}
