.tiles {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 10px;
  //border-bottom: 1px solid #ccc;
  padding: 10px 10px 10px 10px;


  li {
    display: grid;
    border: 1px solid #f3f3f3;
    text-align: center;
    padding: 10px;
    color: #000;

    .set {
      font-weight: bold;
      color: #000;
      text-align: center;
      margin: 6px 0;
    }

    .desc {
      text-align: center;
      .red {
        color: red;
      }
      .blue {
        color: white;
        background-color: $primary-color;
        padding: 2px 5px;
        border-radius: 3px;
      }
      .green {
        color: white;
        background-color: green;
        padding: 2px 5px;
        border-radius: 3px;
      }
      font-size: 12px;
    }
  }
}
